.ac-header
  @apply relative z-0

  .accordion-trigger-icon
    @apply w-5 h-5 md:w-8 md:h-8
    @apply absolute right-0 top-6 md:top-1/2 md:-translate-y-1/2
    @apply origin-center rotate-0 transition-transform

  .is-active &
    .accordion-trigger-icon
      @apply -rotate-45

  &:focus,
  &::-moz-focus-inner
    border: none
    outline: none !important

// .ac-header
//   .is-active &
//     @apply border-b-transparent

.ac-text
  @apply pb-8

.ac.is-active + .ac
  .ac-header
    @apply border-t-transparent
