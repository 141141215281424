@keyframes light
  0%
    // @apply rotate-0
    @apply opacity-100
  12.5%
    // @apply rotate-[5deg]
    @apply opacity-90
  50%
    // @apply rotate-[20deg]
    @apply opacity-70
  75%
    // @apply rotate-[10deg]
    @apply opacity-75
  100%
    // @apply rotate-0
    @apply opacity-100



.lights

  &-layer
    transform: translate3d(1px, 0, -1px)
  //   @apply blur-lg md:blur-2xl xl:blur-3xl 2xl:blur-[80px]

  &-light
    // @apply transition-opacity duration-[0.2s]
    @apply rounded-full
    @apply bg-lilac
    @apply absolute
    // @apply w-64 h-64 md:w-96 md:h-96 xl:w-[25vw] xl:h-[25vw] xl:max-w-[480px] xl:max-h-[480px]
    // @apply blur-lg md:blur-xl xl:blur-2xl
    // @apply transform-gpu rotate-0
    // @apply transition-transform duration-1000
    // transform-origin: 10% 10%
    // animation: light 8s infinite ease-out both 3s


  // &-light-pink
  //   @apply bg-pink
  // &-light-green
  //   @apply bg-green
  // &-light-white
  //   @apply bg-white
  // &-light-mint
  //   @apply bg-mint
  // &-light-purple
  //   @apply bg-purple
  &-light-lilac
    @apply bg-lilac
  // &-light-orange
  //   @apply bg-orange
  &-light-rosa
    @apply bg-rosa


  &-light-tiny
    @apply w-32 h-32 md:w-48 md:h-48 xl:w-[12.5vw] xl:h-[12.5vw] xl:max-w-[240px] xl:max-h-[240px]
    // // @apply blur-sm md:blur-sm xl:blur
  &-light-small
    @apply w-40 h-40 md:w-60 md:h-60 xl:w-[18vw] xl:h-[18vw] xl:max-w-[320px] xl:max-h-[320px]
    // // @apply blur-sm md:blur xl:blur-lg
  &-light-default
    @apply w-64 h-64 md:w-72 md:h-72 xl:w-[25vw] xl:h-[25vw] xl:max-w-[480px] xl:max-h-[480px]
    // // @apply blur md:blur-lg xl:blur-xl
  &-light-big
    @apply w-80 h-80 md:w-96 md:h-96 xl:w-[33vw] xl:h-[33vw] xl:max-w-[640px] xl:max-h-[640px]
    // // // @apply blur-lg md:blur-xl xl:blur-2xl
  &-light-giant
    @apply w-[95vw] h-[95vw] md:w-[55vw] md:h-[55vw] xl:w-[55vw] xl:h-[55vw] xl:max-w-[none] xl:max-h-[none]
  &-light-giant-constraint
    @apply w-[45vw] h-[45vw] md:w-[55vw] md:h-[55vw] xl:w-[55vw] xl:h-[55vw] xl:max-w-[800px] xl:max-h-[800px]
  &-light-error
    @apply w-[125vw] h-[125vw] md:w-[80vw] md:h-[80vw] xl:w-[55vw] xl:h-[55vw] xl:max-w-[800px] xl:max-h-[800px]
