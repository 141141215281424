\:root
  --vh: 100vh

  --c-white:           #FFFFFF
  --c-black:           #000000
  --c-text:            #5a5a24
  --c-text-dark:       #19190a
  --c-text-bright:     #c5fe79

  --c-neutral:         #5a5a24
  --c-border:          #F0F0F4
  --c-neutral-overlay: rgba(0,0,0,0.1)


  --c-pink: #ff99ff
  --c-purple: #aa7fff
  --c-green: #99ff33
  --c-mint: #55ffaa
  --c-orange: rgb(251, 95, 31)


  --c-green-dark: #1c4a3f
  --c-green-bright: #c5fe79
  --c-lilac: rgb(170, 127, 255)
  --c-rosa: rgb(255, 153, 255)
