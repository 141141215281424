.navigation

  &--trigger
    @apply w-16 h-16 fixed z-30 top-0 md:top-2 right-0 md:right-0
    z-index: 100
    border: none
    background: none
    padding: 0
    outline: none !important

    @apply text-text-bright

    &-deco-01,
    &-deco-02,
    &-deco-03,
    &-deco-04
      @apply content-['']
      @apply block w-6 h-[1px] md:h-[2px]
      @apply absolute top-1/2 left-1/2 -translate-x-1/2
      @apply transition-all duration-200 origin-center
      @apply bg-current

    &-deco-01
      @apply rotate-0
      @apply -translate-y-2
    &-deco-02
      @apply rotate-0
      @apply translate-y-2
    &-deco-03
      @apply rotate-0
    &-deco-04
      @apply rotate-0

    .navigation__active &
      &-deco-01
        @apply opacity-0 -translate-y-4
      &-deco-02
        @apply opacity-0 translate-y-4
      &-deco-03
        @apply rotate-45
      &-deco-04
        @apply -rotate-45


  &--header-link
    hyphens: auto
    // @apply px-2
    // @screen md
    //   hyphens: none
