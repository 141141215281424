@charset "UTF-8";
@font-face {
  font-family: "TWKEverett";
  src: url("/assets/fonts/TWKEverett-Bold.woff2") format("woff2"), url("/assets/fonts/TWKEverett-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
:root {
  --vh: 100vh;
  --c-white: #FFFFFF;
  --c-black: #000000;
  --c-text: #5a5a24;
  --c-text-dark: #19190a;
  --c-text-bright: #c5fe79;
  --c-neutral: #5a5a24;
  --c-border: #F0F0F4;
  --c-neutral-overlay: rgba(0,0,0,0.1);
  --c-pink: #ff99ff;
  --c-purple: #aa7fff;
  --c-green: #99ff33;
  --c-mint: #55ffaa;
  --c-orange: rgb(251, 95, 31);
  --c-green-dark: #1c4a3f;
  --c-green-bright: #c5fe79;
  --c-lilac: rgb(170, 127, 255);
  --c-rosa: rgb(255, 153, 255);
}

@tailwind base;
@layer base {}
@tailwind components;
@layer components {}
@tailwind utilities;
@layer utilities {}
.ac-header {
  @apply relative z-0;
}
.ac-header .accordion-trigger-icon {
  @apply w-5 h-5 md:w-8 md:h-8;
  @apply absolute right-0 top-6 md:top-1/2 md:-translate-y-1/2;
  @apply origin-center rotate-0 transition-transform;
}
.is-active .ac-header .accordion-trigger-icon {
  @apply -rotate-45;
}
.ac-header:focus, .ac-header::-moz-focus-inner {
  border: none;
  outline: none !important;
}

.ac-text {
  @apply pb-8;
}

.ac.is-active + .ac .ac-header {
  @apply border-t-transparent;
}

.base-bigtarget {
  @apply before:content-[""] before:absolute before:top-0 before:right-0 before:bottom-0 before:left-0;
}

.button {
  @apply py-2.5 px-5 my-[1.5em];
  @apply inline-flex text-center;
  @apply text-sm text-white font-bold;
  @apply border border-white;
}

.editor {
  @apply text-sm md:text-base;
  @apply tracking-wider;
}
.editor hr {
  @apply my-6 md:my-8;
}
.editor h1 {
  @apply text-2xl md:text-3xl;
  @apply uppercase tracking-wider;
  @apply mb-[0.5em];
}
.editor h2 {
  @apply text-xl md:text-2xl;
  @apply uppercase tracking-wider;
  @apply mb-[0.5em] mt-[2em];
}
.editor h3 {
  @apply text-lg md:text-xl;
  @apply uppercase tracking-widest;
  @apply mb-[0.5em] mt-[1.5em];
}
.editor h4,
.editor h5 {
  @apply text-base md:text-lg;
  @apply uppercase tracking-widest;
  @apply mb-[0.5em] mt-[1.5em];
}
.editor h1,
.editor h2,
.editor h3,
.editor h4,
.editor h5 {
  hyphens: auto;
}
@screen md {
  .editor h1,
  .editor h2,
  .editor h3,
  .editor h4,
  .editor h5 {
    hyphens: none;
  }
}
.editor p,
.editor ol,
.editor ul {
  @apply mb-[1em];
}
.editor p:last-child,
.editor ol:last-child,
.editor ul:last-child {
  @apply mb-0;
}
.editor a {
  @apply decoration-2 underline underline-offset-[0.1em] decoration-current transition-all hover:underline-offset-[0.2em];
}
.editor ol,
.editor ul {
  @apply max-w-3xl mx-auto text-left;
}
.editor ul li {
  @apply mb-1 pl-6 relative;
}
.editor ul li:before {
  content: "— ";
  @apply absolute left-0;
}

.footer {
  transform: translate3d(0, 0, 1px);
}

.img-full img,
.img-full picture,
.img-full svg {
  @apply block w-full h-auto;
}

@keyframes light {
  0% {
    @apply opacity-100;
  }
  12.5% {
    @apply opacity-90;
  }
  50% {
    @apply opacity-70;
  }
  75% {
    @apply opacity-75;
  }
  100% {
    @apply opacity-100;
  }
}
.lights-layer {
  transform: translate3d(1px, 0, -1px);
}
.lights-light {
  @apply rounded-full;
  @apply bg-lilac;
  @apply absolute;
}
.lights-light-lilac {
  @apply bg-lilac;
}
.lights-light-rosa {
  @apply bg-rosa;
}
.lights-light-tiny {
  @apply w-32 h-32 md:w-48 md:h-48 xl:w-[12.5vw] xl:h-[12.5vw] xl:max-w-[240px] xl:max-h-[240px];
}
.lights-light-small {
  @apply w-40 h-40 md:w-60 md:h-60 xl:w-[18vw] xl:h-[18vw] xl:max-w-[320px] xl:max-h-[320px];
}
.lights-light-default {
  @apply w-64 h-64 md:w-72 md:h-72 xl:w-[25vw] xl:h-[25vw] xl:max-w-[480px] xl:max-h-[480px];
}
.lights-light-big {
  @apply w-80 h-80 md:w-96 md:h-96 xl:w-[33vw] xl:h-[33vw] xl:max-w-[640px] xl:max-h-[640px];
}
.lights-light-giant {
  @apply w-[95vw] h-[95vw] md:w-[55vw] md:h-[55vw] xl:w-[55vw] xl:h-[55vw] xl:max-w-[none] xl:max-h-[none];
}
.lights-light-giant-constraint {
  @apply w-[45vw] h-[45vw] md:w-[55vw] md:h-[55vw] xl:w-[55vw] xl:h-[55vw] xl:max-w-[800px] xl:max-h-[800px];
}
.lights-light-error {
  @apply w-[125vw] h-[125vw] md:w-[80vw] md:h-[80vw] xl:w-[55vw] xl:h-[55vw] xl:max-w-[800px] xl:max-h-[800px];
}

.navigation--trigger {
  @apply w-16 h-16 fixed z-30 top-0 md:top-2 right-0 md:right-0;
  z-index: 100;
  border: none;
  background: none;
  padding: 0;
  outline: none !important;
  @apply text-text-bright;
}
.navigation--trigger-deco-01, .navigation--trigger-deco-02, .navigation--trigger-deco-03, .navigation--trigger-deco-04 {
  @apply content-[""];
  @apply block w-6 h-[1px] md:h-[2px];
  @apply absolute top-1/2 left-1/2 -translate-x-1/2;
  @apply transition-all duration-200 origin-center;
  @apply bg-current;
}
.navigation--trigger-deco-01 {
  @apply rotate-0;
  @apply -translate-y-2;
}
.navigation--trigger-deco-02 {
  @apply rotate-0;
  @apply translate-y-2;
}
.navigation--trigger-deco-03 {
  @apply rotate-0;
}
.navigation--trigger-deco-04 {
  @apply rotate-0;
}
.navigation__active .navigation--trigger-deco-01 {
  @apply opacity-0 -translate-y-4;
}
.navigation__active .navigation--trigger-deco-02 {
  @apply opacity-0 translate-y-4;
}
.navigation__active .navigation--trigger-deco-03 {
  @apply rotate-45;
}
.navigation__active .navigation--trigger-deco-04 {
  @apply -rotate-45;
}
.navigation--header-link {
  hyphens: auto;
}

.reveal {
  @apply opacity-0 transition-opacity duration-1000 delay-300;
}
.reveal.reveal__show {
  @apply opacity-100;
}

.video--livestream {
  @apply relative;
}
.video--livestream iframe {
  @apply absolute w-full h-full;
}